.abroomenu-selecthover:hover {
    background-color: #888585;
    color: #ffffff;
}
.abroomenu {
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    cursor: default;
}
.AbrooMenuButtonImage {
    width: 1.0em;
    height: 1.0em;
    margin-left: 0em;
    padding-left: 0;
}
.AbrooMenuButton {
   margin-left: 1em;
   padding-left: 0px;
   padding-right: 0px;
}
