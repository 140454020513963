/*Button style from https://www.bestcssbuttongenerator.com/
Used as:
<button class="toolbarbutton">Share</button>
*/
.toolbarbutton {
	background:linear-gradient(to bottom, #44c767 5%, #3d7409 100%);
	background-color:#44c767;
	border-radius:4px;
	border:1px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
    font-size:17px;
    font-weight:bold;
	padding:9px 31px;
	text-decoration:none;
    text-shadow:0px 1px 0px #2f6627;
    margin-bottom: 1em;
    margin-left: 1em;
    margin-right: 1em;
}
.toolbarbutton:hover {
	background:linear-gradient(to bottom, #3d7409 5%, #44c767 100%);
	background-color:#3d7409;
}
.toolbarbutton:active {
	position:relative;
	top:1px;
}

        